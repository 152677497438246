
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'LoadingIndicatorCircle',
    props: {
        size: {
            type: String,
            default: null,
        },
        strokeWidth: {
            type: String,
            default: null,
        },
    },
    computed: {
        styles(): Record<string, string> {
            const stylesObj: Record<string, string> = {};
            if (this.size) {
                stylesObj.height = this.size;
                stylesObj.width = this.size;
            }
            return stylesObj;
        },
        pathStyles(): Record<string, string> {
            const stylesObj: Record<string, string> = {};
            if (this.strokeWidth) {
                stylesObj.strokeWidth = this.strokeWidth;
            }
            return stylesObj;
        },
        pathClasses(): Record<string, boolean> {
            return {
            };
        },
    },
});
