
import { defineComponent } from 'vue';

export default defineComponent({
    name: 'LoadingIndicatorBeam',
    props: {
    },
    computed: {
    },
});
